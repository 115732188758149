import React, { useState, useEffect, useContext } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import "./pages.scss"
import BackArrow from "../images/icons/arrow-left.png"
import SEO from "../components/seo";
import QuoteImage from "../images/icons/quote.png"
import WiseJourney from '../components/WiseJourneys/ModuleDetail';
import '../components/WiseJourneys/wise-journeys.scss'
import { api } from '../../utils';
import UserContext from "../context/UserContext"

const WiseSpeak1 = 'https://cgs-cdn.sgp1.cdn.digitaloceanspaces.com/parent-wise-prod/website-assets/wise-speak-1.png'
const WiseSpeak2 = 'https://cgs-cdn.sgp1.cdn.digitaloceanspaces.com/parent-wise-prod/website-assets/wise-speak-2.png'
const Logo = "https://cgs-cdn.sgp1.cdn.digitaloceanspaces.com/parent-wise-prod/website-assets/bg/top-right-pattern.png";

const WiseSpeakDetail = ({ data, location }) => {
  const userData = useContext(UserContext);
  const user = userData && userData.user;
  const [bookmarks, setBookmarks] = useState([]);

  const groupJourney = data.cms.page.acf_wise_journey_page.groupJourney;
  const onlineJourney = data.cms.page.acf_wise_journey_page.onlineJourney;
    
    const newArray = data.cms.wiseSpeaks.nodes.map((item, idx) => {
        if (idx % 2) {
            return Object.assign({}, item, { img: WiseSpeak2 });
        } else {
            return Object.assign({}, item, { img: WiseSpeak1 });
        }
    });

    useEffect(() => {
      if (user) {
        fetchBookmarks();
      }
    }, [user]);

    const fetchBookmarks = () => {
      api.get('/api/v1/user-bookmark/list/wise-journey')
      .then(resp => {
        if (resp && resp.success && resp.data && resp.data.length) {
          let bookmarks = [];
          resp.data.forEach(i => bookmarks.push(i.slug));
          setBookmarks(bookmarks);
        } else {
          setBookmarks([]);
        }
      });
    }
    
    return (
        <div className="about-us-background">
            <Layout>
                <SEO
                    title="Join a WiseJourney: WiseSpeak"
                    description={`Promoting early language & literacy`}
                    image={`${process.env.GATSBY_SITE_URL}/images/wj_wise-sepeak-logo-og.png`}
                    path={location.pathname}
                />
                <img loading="lazy" className="super-ho-logo w3-hide-small" src={Logo} />
                <div className="w3-row" style={{ position: "relative" }}>
                    <Link to="/wise-journey" className="w3-hide-small pw-wt-detail-back-to" style={{ position: "absolute" }}>
                        <img src={BackArrow} className="pw-wt-detail-back-icon" />
                        <div className="">
                            Back to WiseJourney
                        </div>
                    </Link>
                    <Link to="/wise-journey#wise-speak" className="w3-hide-large w3-hide-medium pw-wt-detail-back-to" style={{ position: "absolute" }}>
                        <img src={BackArrow} className="pw-wt-detail-back-icon" />
                        <div className="pw-jm-back">
                            Back to WiseJourneys
                        </div>
                    </Link>
                </div>
                <div className="w3-hide-large w3-hidconsole.log('location: ', location);e-medium pw-ws-bor"> 
                    <Link to="/wise-self" className="about-us-button">WiseSelf</Link>
                    <Link to="/wise-speak" style={{marginLeft: "15px"}} className="about-us-button about-us-border">WiseSpeak</Link>
                </div>
                <div className="w3-hide-small head-section">
                    <img loading="lazy" className="quote-image" src={QuoteImage} />
                    <div className="header">WiseSpeak</div>
                </div>
                
                {(newArray && newArray.length) ?
                  <WiseJourney  
                      user={user}
                      data={newArray} 
                      type={'wiseSpeak'} 
                      location={location} 
                      groupJourney={groupJourney} 
                      onlineJourney={onlineJourney}
                      bookmarks={bookmarks} fetchBookmarks={fetchBookmarks} 
                  />
                  :
                  <div className="w3-center" style={{ minHeight: '60vH' }}>
                    <p className="w3-padding-64">No content to display!</p>
                  </div>
                }
                
            </Layout>
        </div>
    )
}

export const query = graphql`
  {
    cms {
      page(id: "wise-journey", idType: URI) {
        acf_wise_journey_page {
          groupJourney
          onlineJourney
        }
      }
      wiseSpeaks(first: 1000) {
        nodes {
          acf_wise_speak {
            date
            description
            themeColor
            enableSurvey
            sessions {
              sessionDetail
              title
            }
          }
          slug
          status
          title
          ageStages {
            nodes {
              slug
              name
              description
            }
          }
        }
      }
    }
  }
`

export default WiseSpeakDetail;