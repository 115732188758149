import React,{ useState } from 'react';
import './cookie-policy.scss';

const CookiePolicy = () => {
    
  const isBrowser = () => typeof window !== "undefined";
  const [isCookiePolicyAgreed, setCookiePolicyAgreed] = useState(isBrowser() && window.localStorage.getItem('pwCookieConsentAgreed'));

    return (
        <div>
     { isCookiePolicyAgreed !== 'true' &&
      <div className="pw-cookie-popup" style={{ display: 'block' }}>
        <div className="cookie-container">
          <div className="close-button" onClick={() => {
                  isBrowser() && window.localStorage.setItem('pwCookieConsentAgreed', 'true');
                  return setCookiePolicyAgreed('true');
               }}>
                  ×
          </div>
           <div className="row">
             <div>
               <p>
                <span className="cookie-text"> This website uses cookies to personalize your experience and to analyze site traffic. By continuing to browse the site, you are agreeing to our terms. Please refer to our privacy policy&nbsp;<a href="/privacy-policy">here.</a> </span> 
               </p>
             </div>
           </div>
       </div>
      </div>
    }
      </div>
    );
}

export default CookiePolicy