/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./../components/common/Header"
import Footer from "./../components/common/Footer"
import CookiePolicy from "./common/CookiePolicy"
import "./layout.scss"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  useEffect(() => {
    document.addEventListener("click", closeInfoTip);

    return function cleanup() {
      document.removeEventListener("click", closeInfoTip);
    };
  }, []);

  const closeInfoTip = (evt) => {
    const infoTips = document.getElementsByClassName("pw-info-title active");
    if (infoTips && infoTips.length) {
      for (let i=0; i<infoTips.length; i++) {
        if (infoTips[i] && infoTips[i] != evt.target) {
          infoTips[i].classList.remove('active');
        }
      }
    }
  }

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <div>
        <main>{children}</main>
      </div>
      <Footer />
      <CookiePolicy />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
