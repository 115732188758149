import React, { useState } from 'react';
import './wise-journeys.scss';
import ShareImage from '../../images/icons/share.png'
import LikeImage from '../../images/icons/like.png'
import LikeIconActive from '../../images/icons/like-active.png';
import PWMarkdown from '../common/PWMarkdown'
import SocialShare from '../common/SocialButtons'
import { api } from '../../../utils';

const WiseJourneys = (props) => {
    const [openCls, setOpenCls] = useState(false)
    const { data, type, location, groupJourney, onlineJourney, user, bookmarks, fetchBookmarks } = props;

    const getCategories = (data) => {
        if (!data || !data.nodes || !data.nodes.length) {
            return null;
        }
        let categoryList = [];
        data.nodes.forEach(item => {
            categoryList.push(item.name)
        });

        return categoryList.join(' & ');
    }

    const bookmarkTip = (slug) => {
        const fData = {
            type: 'wise-journey',
            slug
        }
        api.post('/api/v1/user-bookmark/toggle', fData)
        .then(resp => {
            if (resp && resp.success) {
                fetchBookmarks();
            } else {
                alert("Something went wrong. Please try again later!");
            }
        });
    }

    return (
        <div className="pw-journey-detail-page">
            {data.map((item) => {
                const module = type === 'wiseSelf' ? item.acf_wise_self : item.acf_wise_speak
                const ref = type === 'wiseSelf' ? 'wise-self' : 'wise-speak'
                return <div id={item.slug} className={`w3-content margin ${module.enableSurvey == "no" ? "padding" : ""}`}>
                    <div className="wise-self-img-div">
                        <img loading="lazy" className="wise-self-img" src={item.img} />
                    </div>
                    <div className="w3-row card" 
                        style={{borderColor: `${module.themeColor}`, color : `${module.themeColor}`}}>
                        <div className="w3-col m9 s9">
                            <div className="card-header"> UPCOMING MODULE </div>
                            <div className="card-title"> {item.title} </div>
                            <div className="title-padding card-title"> ({module.date}) | {getCategories(item.ageStages)} </div>
                        </div>
                        <div className="w3-margin-top w3-col m3 s3">
                            <div className="w3-row w3-right icons">
                                {(user) && (
                                    <button style={{ background: 'none', padding: 0, border: 'none', outline: 'none', cursor: 'pointer' }}
                                        onClick={(evt) => {
                                            evt.preventDefault();
                                            evt.stopPropagation();
                                            bookmarkTip(item.slug);
                                        }}
                                    > 
                                        <img className="img-mrgn like-img" src={(bookmarks.indexOf(item.slug) !== -1) ? LikeIconActive : LikeImage} />
                                    </button>
                                )}
                                <img className={`share-img ${openCls == false ? "w3-show" : "w3-hide"}`} src={ShareImage} onClick={() => {setOpenCls(true)}} /> 
                                <SocialShare data={openCls} handleSetOpenCls={(someData) => {setOpenCls(someData)}} 
                                    title={item.title} link={`${location.origin}/${ref}#${item.slug}`} 
                                    subjectTitle={'Check out this journey'} description={module.description}
                                /> 
                            </div>
                        </div>
                    </div>
                    <div className="view">
                        <PWMarkdown data={module.description} />
                    </div>
                    {module.sessions.map((session, idx) => ( 
                        <div className="sessions">
                            <div className="heading view" style={{color : `${module.themeColor}`}}>
                                {`Session ${idx +1}`}
                            </div>
                            <div className="title view" style={{color : `${module.themeColor}`}}>
                                {session.title}
                            </div>
                            <div className="view">
                                <PWMarkdown data={session.sessionDetail} />
                            </div>
                        </div>
                    ))}
                    {module.enableSurvey == "yes" ? <>
                        {(groupJourney || onlineJourney) ? <>
                            <div className="w3-hide-small w3-center signup">Sign up now!</div>
                            <div className="w3-hide-medium w3-hide-large w3-center signup">Sign up for a</div>
                        </> : null}
                        <div className="w3-row w3-row-pdding suvey-btn">
                            {groupJourney ?
                                <div className="w3-col s6 m6 pw-cf-submit-section btn-1">
                                    <button className="w3-round-xxlarge w3-button pw-cf-submit-btn">
                                        <a href={groupJourney} target="_blank" rel="noreferrer" className="pw-cf-sub-btn-text">
                                            Group Journey <span className="pw-cf-sub-btn-arrow" />
                                        </a>
                                    </button>
                                </div>
                            : null}
                            {onlineJourney ? 
                                <div className="w3-col s6 m6 pw-cf-submit-section btn-2">
                                    <button className="w3-round-xxlarge w3-button pw-cf-submit-btn">
                                        <a href={onlineJourney} target="_blank" rel="noreferrer" className="pw-cf-sub-btn-text">
                                            Online Journey <span className="pw-cf-sub-btn-arrow" />
                                        </a>
                                    </button>
                                </div>
                            : null}
                        </div> 
                    </> : ''}
                </div>
            })}
        </div>
    )
}

export default WiseJourneys;